<template>
  <v-row>
    <v-col>
      <v-select v-model="field" :items="filteredFormFields" :dense="dense" label="Form field" outlined>
        <template v-slot:append-outer>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon color="error" @click="$emit('remove')">
                <v-icon>fal fa-times-circle</v-icon>
              </v-btn>
            </template>
            <span>Remove this field</span>
          </v-tooltip>
        </template>
      </v-select>
    </v-col>
    <v-col>
      <v-autocomplete
        v-if="fieldType === 'select'"
        v-model="itemValue"
        :items="fieldOptions"
        :dense="dense"
        label="Value(s)"
        multiple
        chips
        small-chips
        deletable-chips
        outlined></v-autocomplete>
      <v-text-field
        v-else-if="fieldType === 'number'"
        v-model="itemValue"
        :dense="dense"
        type="number"
        label="Min Value"
        outlined></v-text-field>
      <v-select
        v-else-if="fieldType === 'text'"
        v-model="itemValue"
        :items="textOptions"
        :dense="dense"
        label="Matching Rule"
        outlined></v-select>
      <v-select
        v-else-if="fieldType === 'checkbox'"
        v-model="itemValue"
        :items="checkOptions"
        :dense="dense"
        label="Matching Rule"
        outlined></v-select>
    </v-col>
    <v-col v-if="fieldType === 'number'">
      <v-text-field  v-model="itemValueMax" :dense="dense" label="Max Value" type="number" outlined></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  props: {
    value: {
      type: Object,
      required: true,
      validator: (value) => {
        if (!('field' in value) || !('value' in value)) return false
        return true
      }
    },
    formFields: {
      type: Array,
      default: () => {
        return []
      }
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root, emit }) {
    const filteredFormFields = computed(() => props.formFields.filter(({ input }) => !/block$/.test(input)))
    const field = ref('')
    const fieldSettings = computed(() => {
      if (props.formFields.length > 0) {
        for (let i = 0; i < props.formFields.length; i++) {
          if (props.formFields[i].value === field.value) {
            return props.formFields[i]
          }
        }
      }
      return { input: '', options: '' }
    })
    const fieldType = computed(() => {
      switch (fieldSettings.value.input) {
        case 'select':
        case 'autocomplete':
        case 'combobox':
        case 'radio':
          return 'select'
        case 'number':
        case 'slider':
        case 'rangeSlider':
          return 'number'
        case 'checkbox':
        case 'switch':
          return 'checkbox'
      }
      return 'text'
    })
    const fieldOptions = ref([])
    watch(fieldSettings, (val) => {
      if ('options' in val && val.options !== '' && val.options != null) {
        root.$feathers.service('forms/select-options').get(val.options).then((data) => {
          if ('options' in data) fieldOptions.value = data.options
        })
      }
    })
    const itemValue = ref('')
    const itemValueMax = ref('')

    watch(() => props.value, () => {
      if (props.value != null) {
        field.value = props.value.field
        if (props.formFields.length > 0) {
          for (let i = 0; i < props.formFields.length; i++) {
            if (props.formFields[i].value === field.value) {
              if (props.formFields[i].input === 'number') {
                itemValue.value = props.value.value
                itemValueMax.value = props.value.valueMax
                return
              }
            }
          }
        }
        itemValue.value = props.value.value
      }
    })

    watch([field, itemValue, itemValueMax], () => {
      let doUpdate = false
      if (field.value !== props.value.field) doUpdate = true
      if (itemValue.value !== props.value.value) doUpdate = true
      if (fieldType.value === 'number' && itemValueMax.value !== props.value.valueMax) doUpdate = true

      if (doUpdate) {
        let obj = { field: field.value, value: itemValue.value }
        if (fieldType.value === 'number' && itemValueMax.value !== props.value.valueMax) obj.valueMax = itemValueMax.value
        emit('input', obj)
      }
    })

    const textOptions = ref([
      { text: 'Empty Input', value: 'nul' },
      { text: 'Non-Empty Input', value: 'val' }
    ])
    const checkOptions = ref([
      { text: 'Checked', value: 'Y' },
      { text: 'Not Checked', value: 'N' }
    ])

    return {
      filteredFormFields,
      field,
      fieldSettings,
      fieldType,
      fieldOptions,
      itemValue,
      itemValueMax,
      textOptions,
      checkOptions
    }
  }
}
</script>
